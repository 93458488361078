import { RedirectDTO } from "../RedirectorService";

export const defaultRedirectDto = {
  targetUrl: "",
  aliasSuffix: null,
  enabled: true,
  validFrom: null,
  validTo: null,
  splash: false,
  disposable: false,
  application: null,
  isDeleted: false,
} as RedirectDTO;
