import * as React from "react";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Spinner,
  useToastController,
} from "@fluentui/react-components";
import { useStylesTable } from "../fluentui-styles/TableStyle";
import { TableProps } from "../interfaces/TableProps";
import { columnSizingOptions, getColumns } from "./TableColumns";
import { RedirectDTO } from "../../RedirectorService";
import ClipLoader from "react-spinners/ClipLoader";
import { ThemeContext, ToasterContext } from "../../utils/GlobalContexts";
import { useContext, useState } from "react";
import { Pagination } from "antd";
import { lightTheme } from "../../utils/FluentThemeConstructor";

const TableComponent: React.FC<TableProps> = ({
  data,
  selectedDto,
  setSelectedDto,
  isLoading,
  openEditFormDialogWindow,
  lastEditedRedirectId,
}) => {
  const { toasterId } = useContext(ToasterContext);
  const { dispatchToast } = useToastController(toasterId);
  const styles = useStylesTable();
  const columns = getColumns(dispatchToast);
  const { theme } = useContext(ThemeContext);
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  React.useEffect(() => {
    setPageCount(Math.ceil((data?.length ?? 0) / pageSize));
  }, [data]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * pageSize) % data.length;

    console.log("newOffset", newOffset);
  };

  const handleRowClick = (e: any, item: RedirectDTO) => {
    setSelectedDto(item);
    if (e.detail === 2) {
      openEditFormDialogWindow();
    }
  };

  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  const getRowColor = (item: any) => {
    if ((item as any).rowKey === lastEditedRedirectId)
      return styles.lastEditedRow;
    else if (selectedDto?.rowKey === (item as any).rowKey)
      return styles.selectedRow;
    else if (item?.validTo && new Date(item?.validTo) < new Date())
      return styles.pastValidDate;
    else return styles.row;
  };

  function getPageData() {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return data?.slice(startIndex, endIndex);
  }

  return isLoading ? (
    <div
      style={{
        textAlign: "center",
        margin: "20px",
      }}
    >
      <ClipLoader size={60} color="#5F6371" />
    </div>
  ) : data?.length === 0 ? (
    <div
      style={{
        textAlign: "center",
        margin: "20px",
      }}
    >
      <div>Nincsenek megjeleníthető adatok</div>
    </div>
  ) : (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100vh - 250px)",
      }}
    >
      <DataGrid
        size="small"
        columns={columns}
        items={getPageData()}
        sortable
        resizableColumns
        getRowId={(item) => item.rowKey}
        style={{
          borderCollapse: "collapse",
        }}
        columnSizingOptions={columnSizingOptions}
      >
        <DataGridHeader
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 1000,
          }}
        >
          <DataGridRow id="table-header">
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <DataGridHeaderCell
                  ref={(el) => (refMap.current[columnId] = el)}
                  className={styles.headerCell}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>

        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow
              key={rowId}
              className={getRowColor(item)}
              onClick={(e: any) => handleRowClick(e, item as any)}
            >
              {({ renderCell }) => (
                <DataGridCell className={styles.bodyCell}>
                  {renderCell(item)}
                </DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      <div
        style={{
          position: "absolute",
          bottom: 10,
          width: "100%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Pagination
          style={
            theme === lightTheme
              ? {
                  fontWeight: 600,
                  width: "fit-content",
                  position: "relative",
                  margin: "auto",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: "#5F6371",
                }
              : {
                  fontWeight: 600,
                  color: "#ffffff",
                  padding: "5px 10px",
                }
          }
          // showSizeChanger
          defaultPageSize={pageSize}
          total={data?.length}
          showTotal={(total: any, range: any) =>
            `${range[0]}-${range[1]} az ${total} elemből`
          }
          onChange={(page: number, pageSize: number) => {
            setCurrentPage(page);
          }}
          defaultCurrent={1}
        />
      </div>
    </div>
  );
};

export default TableComponent;
