import {
  BrandVariants,
  Theme,
  createLightTheme,
  createDarkTheme,
} from "@fluentui/react-components";

export const blueTheme: BrandVariants = {
  10: "#010304",
  20: "#101820",
  30: "#182636",
  40: "#263242",
  50: "#343D4D",
  60: "#424A59",
  70: "#515665",
  80: "#5F6371",
  90: "#6E717E",
  100: "#7C7E8A",
  110: "#8B8C97",
  120: "#9A9AA4",
  130: "#AAA9B2",
  140: "#B9B8BF",
  150: "#C8C7CD",
  160: "#D7D6DA",
};

export const lightTheme: Theme = {
  ...createLightTheme(blueTheme),
};

export const darkTheme: Theme = {
  ...createDarkTheme(blueTheme),
};
