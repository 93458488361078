import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useStylesMainPage = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },

  buttons: { display: "flex", justifyContent: "space-between" },
  button: { ...shorthands.margin("15px", "5px") },
  loginButton: {
    ...shorthands.padding("18px"),
    fontSize: "24px",
    fontWeight: 700,
    minWidth: "50%",
    ...shorthands.borderRadius("5px"),
    ...shorthands.margin("2em"),
  },
  buttonContainer: {
    height: "50vh",
    width: "40vw",
    position: "relative",
    ...shorthands.padding(tokens.spacingHorizontalXXL),
    ...shorthands.gap(tokens.spacingVerticalM),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.borderRadius("5px"),
  },
  loginAppTitle: {
    top: 0,
    position: "absolute",
    marginTop: "40px",
    fontSize: "40px",
  },
  main: {
    ...shorthands.margin("0", "20px"),
    ...shorthands.padding("5px"),
    marginTop: "20px",
  },
});
