import * as React from "react";
import { DialogConfirmProps } from "../interfaces/DialogConfirmProps";
import DialogSkeleton from "./DialogSkeleton";
import { CONFIRM } from "../../utils/Enums";

const DialogConfirmComponent: React.FC<DialogConfirmProps> = ({
  open,
  setOpen,
  handleAction,
  type,
}) => {
  const getText = () => {
    switch (type) {
      case CONFIRM.DELETE_DTO:
        return "Biztosan törölni szeretnéd a redirectet?";
      case CONFIRM.UNSAVED_CHANGES:
        return "Vannak mentetlen változtatások, biztosan be szeretné zárni?";
      case CONFIRM.NO_CHANGES_TO_SAVE:
        return "Nincsenek menthető változtatások.";
      default:
        return "";
    }
  };
  return (
    <DialogSkeleton
      confirmType={type !== null && type !== undefined}
      open={open}
      setOpen={setOpen}
      title="Figyelmeztetés"
      saveAction={handleAction}
      saveBtnText={"OK"}
    >
      <div>{getText()}</div>
    </DialogSkeleton>
  );
};

export default DialogConfirmComponent;
