// src/components/PageFooter.tsx

import React from "react";
import { useStylesFooter } from "../fluentui-styles/FooterStyle";
import { FooterProps } from "../interfaces/FooterProps";
import { ReactComponent as KsLogo } from "../../assets/ks/ks-logo-text.svg";

const Footer: React.FC<FooterProps> = ({ mainPage }) => {
  const styles = useStylesFooter();
  return (
    <footer
      className={`${styles.footer} ${
        mainPage ? styles.mainPagePosition : styles.otherPosition
      }`}
    >
      <KsLogo style={{ height: "50px" }} />
      <span className={styles.text}>© 2023 Kulcs-Soft Nyrt.</span>
    </footer>
  );
};

export default Footer;
