import {
  createTableColumn,
  TableCellLayout,
  Button,
  TableColumnDefinition,
  Tooltip,
} from "@fluentui/react-components";
import { RedirectDTO } from "../../RedirectorService";
import { formatDate } from "../../utils/HelperFunctions";
import { CheckmarkRegular, DismissRegular } from "@fluentui/react-icons";
import { LinkIcon } from "../../utils/Icons";
import { notify } from "../../utils/DispatchToaster";
import { TOAST } from "../../utils/Enums";

export const columnSizingOptions = {
  rowKey: {
    minWidth: 30,
    defaultWidth: 120,
  },
  tagetUrl: {
    minWidth: 30,
    defaultWidth: 230,
  },
  aliasSuffix: {
    minWidth: 30,
    defaultWidth: 100,
  },
  link: {
    minWidth: 30,
    defaultWidth: 30,
  },
  validFrom: {
    minWidth: 30,
    defaultWidth: 130,
  },
  validTo: {
    minWidth: 30,
    defaultWidth: 120,
  },
  lastUsed: {
    minWidth: 30,
    defaultWidth: 120,
  },
  splash: {
    minWidth: 30,
    defaultWidth: 60,
  },
  disposable: {
    minWidth: 30,
    defaultWidth: 70,
  },
  createdBy: {
    minWidth: 30,
    defaultWidth: 100,
  },
  enabled: {
    minWidth: 30,
    defaultWidth: 30,
  },
  clickCount: {
    minWidth: 30,
    // defaultWidth: 30,
  },
};

export const getColumns = (dispatchToast: any) =>
  [
    createTableColumn<RedirectDTO>({
      columnId: "rowKey",
      renderHeaderCell: () => "Id",
      compare: (a, b) => {
        return a.rowKey!.localeCompare(b.rowKey ?? "");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={(item.rowKey as string) ?? ""}
              relationship={"description"}
            >
              <span>{item.rowKey}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<RedirectDTO>),
    createTableColumn<RedirectDTO>({
      columnId: "tagetUrl",
      renderHeaderCell: () => "Cél url",
      compare: (a, b) => {
        return a.targetUrl!.localeCompare(b.targetUrl ?? "");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={(item.targetUrl as string) ?? ""}
              relationship={"description"}
            >
              <span>{item.targetUrl}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "aliasSuffix",
      renderHeaderCell: () => "Alias",
      compare: (a, b) => {
        const aliasSuffixA = a.aliasSuffix || "";
        const aliasSuffixB = b.aliasSuffix || "";
        return aliasSuffixA.localeCompare(aliasSuffixB);
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={(item.aliasSuffix as string) ?? ""}
              relationship={"description"}
            >
              <span>{item.aliasSuffix}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "link",
      renderHeaderCell: () => "Link",
      renderCell: (item) => {
        return (
          <TableCellLayout>
            <LinkIcon
              style={{ marginTop: "5px" }}
              className="scaledIcon"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_REDIRECTOR_API_URI}/${
                    item?.aliasSuffix ?? ""
                  }`
                );
                notify(TOAST.SUCCESS, "Hivatkozás másolva", dispatchToast);
              }}
            />
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "validFrom",
      renderHeaderCell: () => "Érvényesség kezdet",
      compare: (a, b) => {
        const dateA = a.validFrom ? new Date(a.validFrom) : null;
        const dateB = b.validFrom ? new Date(b.validFrom) : null;

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return -1;
        } else if (dateB === null) {
          return 1;
        } else {
          return dateA.getTime() - dateB.getTime();
        }
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.validFrom ? new Date(item.validFrom) : undefined)}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "validTo",
      compare: (a, b) => {
        const dateA = a.validTo ? new Date(a.validTo) : null;
        const dateB = b.validTo ? new Date(b.validTo) : null;

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return -1;
        } else if (dateB === null) {
          return 1;
        } else {
          return dateA.getTime() - dateB.getTime();
        }
      },
      renderHeaderCell: () => "Érvényesség vége",
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.validTo ? new Date(item.validTo) : undefined)}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "lastUsed",
      renderHeaderCell: () => "Utoljára használva",
      compare: (a, b) => {
        const dateA = a.lastUsed ? new Date(a.lastUsed) : null;
        const dateB = b.lastUsed ? new Date(b.lastUsed) : null;

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return -1;
        } else if (dateB === null) {
          return 1;
        } else {
          return dateA.getTime() - dateB.getTime();
        }
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.lastUsed ? new Date(item.lastUsed) : undefined)}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "splash",
      renderHeaderCell: () => "Átirányító oldal",
      compare: (a, b) => {
        const splashA = a.splash === true ? 1 : a.splash === false ? -1 : 0;
        const splashB = b.splash === true ? 1 : b.splash === false ? -1 : 0;

        return splashA - splashB;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            {item.splash ? <span>✅</span> : <span>❌</span>}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "disposable",
      renderHeaderCell: () => "Egyszer használható",
      compare: (a, b) => {
        const disposableA =
          a.disposable === true ? 1 : a.disposable === false ? -1 : 0;
        const disposableB =
          b.disposable === true ? 1 : b.disposable === false ? -1 : 0;

        return disposableA - disposableB;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            {item.disposable ? <span>✅</span> : <span>❌</span>}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "createdBy",
      renderHeaderCell: () => "Készítő",
      compare: (a, b) => {
        const createdByA = a.createdBy || "";
        const createdByB = b.createdBy || "";
        return createdByA.localeCompare(createdByB);
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={(item.createdBy as string) ?? ""}
              relationship={"description"}
            >
              <span>{item.createdBy}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "enabled",
      renderHeaderCell: () => "Aktív",
      compare: (a, b) => {
        const enabledA = a.enabled === true ? 1 : a.enabled === false ? -1 : 0;
        const enabledB = b.enabled === true ? 1 : b.enabled === false ? -1 : 0;

        return enabledA - enabledB;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout>
            {item.enabled ? <span>✅</span> : <span>❌</span>}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<RedirectDTO>({
      columnId: "clickCount",
      renderHeaderCell: () => "Kattintások",
      compare: (a, b) => {
        const clickCountA = a.clickCount ?? 0;
        const clickCountB = b.clickCount ?? 0;
        return clickCountA - clickCountB;
      },
      renderCell: (item) => {
        return <TableCellLayout>{item.clickCount ?? 0}</TableCellLayout>;
      },
    }),
  ] as TableColumnDefinition<RedirectDTO>[];
