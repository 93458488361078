import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesInput = makeStyles({
  datepicker: {
    width: "120px",
    marginRight: "5px",
  },
  reset: {
    marginBottom: "-5px",
    marginLeft: "7px",
  },
});
