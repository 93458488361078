import axios from "axios";
import { RedirectDTO } from "../RedirectorService";

export const formatDate = (date: Date | undefined): string => {
  if (date) {
    const formattedDate = new Date(date);

    const year = formattedDate.getFullYear();
    const month = ("0" + (formattedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + formattedDate.getDate()).slice(-2);
    const hours = ("0" + formattedDate.getHours()).slice(-2);
    const minutes = ("0" + formattedDate.getMinutes()).slice(-2);

    return `${year}.${month}.${day}. ${hours}:${minutes}`;
  }
  return "";
};

export const cutLongString = (
  string: string | null | undefined,
  limit: number
) => {
  if (!string) return;
  let editedString = "";
  if (string.length > limit) {
    editedString = string.substring(0, limit) + "...";
  }
  return editedString.length > 0 ? editedString : string;
};

export async function retrieveProfilePhoto(token: string | void) {
  try {
    const response = await axios.get(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}

export async function retrieveProfileInfo(token: string | void) {
  try {
    const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}

export const isValidUrl = (url: string | null | undefined): boolean => {
  if (!url) return false;
  url = url.toLowerCase();
  return url.indexOf("http://") === 0 || url.indexOf("https://") === 0;
};

export function areRedirectDTOsEqual(
  dto1: RedirectDTO | null,
  dto2: RedirectDTO | null
) {
  if (dto1 === null || dto2 === null) {
    return true;
  }
  const keys = Object.keys(dto1) as Array<keyof RedirectDTO>;
  for (const key of keys) {
    if (dto1[key] !== dto2[key]) return false;
  }
  return true;
}
