import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@fluentui/react-components";
import DialogActionsComponent from "./DialogActionsComponent";
import { DialogSkeletonProps } from "../interfaces/DialogSkeletonProps";
import { CONFIRM } from "../../utils/Enums";

const DialogSkeleton: React.FC<DialogSkeletonProps> = ({
  open,
  setOpen,
  title,
  closeBtnText,
  saveBtnText,
  saveAction,
  handleDialogOpenStateChange,
  children,
  confirmType,
}) => {
  const handleStateChange = (event: any, data: any) => {
    if (handleDialogOpenStateChange !== undefined)
      handleDialogOpenStateChange(data.open);
    else setOpen(data.open);
  };
  return (
    <Dialog open={open} onOpenChange={handleStateChange}>
      <DialogSurface style={confirmType ? { maxWidth: "400px" } : {}}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActionsComponent
            closeBtnText={closeBtnText ? closeBtnText : "Mégse"}
            saveBtnText={saveBtnText ?? "OK"}
            saveAction={saveAction}
          />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DialogSkeleton;
