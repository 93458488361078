export enum INTERNAL_ROUTE {
  START = "/",
  REDIRECTS = "/redirects",
}

export enum TOAST {
  WARN = "warning",
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  PROGRESS = "progress",
}

export enum LOCAL_STORAGE_KEY {
  MS_AUTH_TOKEN = "msAuthToken",
  FILTER = "filter",
}

export enum CONFIRM {
  UNSAVED_CHANGES,
  DELETE_DTO,
  NO_CHANGES_TO_SAVE,
}

export enum FILTER {
  ALL_APP = "Összes alkalmazás",
  ALL_STATE = "Összes állapot",
}
