import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesFormDialog = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    width: "100%",
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
  item: {
    marginBottom: "3px",
  },

  labelRow: { marginBottom: "-20px" },
  labelRowItem: { width: "50%" },
});
