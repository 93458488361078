import {
  Button,
  Combobox,
  Input,
  Switch,
  useId,
  Option,
  useToastController,
  Tooltip,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  RedirectDTO,
  RedirectsService,
  RedirectCreateUpdateDTO,
  ApiError,
} from "./RedirectorService";
import DialogConfirmComponent from "./components/dialog/DialogConfirmComponent";
import DialogFormComponent from "./components/dialog/DialogFormComponent";
import { useStylesMainPage } from "./components/fluentui-styles/MainPageStyle";
import { notify } from "./utils/DispatchToaster";
import { CONFIRM, FILTER, LOCAL_STORAGE_KEY, TOAST } from "./utils/Enums";
import { defaultRedirectDto } from "./utils/GlobalContants";
import {
  ToasterContext,
  RefreshContext,
  FilterContext,
} from "./utils/GlobalContexts";
import { areRedirectDTOsEqual, isValidUrl } from "./utils/HelperFunctions";
import {
  AddIcon,
  SearchIcon,
  ResetIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "./utils/Icons";
import InputComponent from "./components/input/InputComponent";
import { Filters } from "./models/Filters";
import TableComponent from "./components/table/TableComponent";
import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export default function RedirectsPage() {
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedDto, setSelectedDto] = useState<RedirectDTO | null>(null);
  const [unsavedDto, setUnsavedDto] = useState<RedirectDTO | null>(null);
  const [lastEditedRedirectId, setLastEditedRedirectId] = useState<
    string | null | undefined
  >(null);
  const styles = useStylesMainPage();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialogType, setConfirmDialogType] = useState(
    CONFIRM.UNSAVED_CHANGES
  );
  const { filters, setFilters } = useContext(FilterContext);
  const [applications, setApplications] = useState<any>(null);

  const { toasterId } = useContext(ToasterContext);
  const { dispatchToast } = useToastController(toasterId);

  const [data, setData] = useState([] as RedirectDTO[]);
  const { refresh, setRefresh } = useContext(RefreshContext);
  const [newGuid, setNewGuid] = useState<string | null>(null);

  const openFormDialogWindow = (defaultData: RedirectDTO) => {
    setSelectedDto(null);
    setNewGuid(uuidv4());
    setUnsavedDto(defaultData);
    setOpenFormDialog(true);
  };

  const openEditFormDialogWindow = () => {
    setNewGuid(null);
    setUnsavedDto(selectedDto);
    setOpenFormDialog(true);
  };

  useEffect(() => {
    let localStorageFilters = localStorage.getItem(LOCAL_STORAGE_KEY.FILTER);
    if (localStorageFilters)
      try {
        setFilters(JSON.parse(localStorageFilters));
      } catch {}
    getData();
  }, []);

  useEffect(() => {
    if (refresh) {
      setIsLoading(true);
      getData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (lastEditedRedirectId) {
      setTimeout(() => {
        setLastEditedRedirectId(null);
      }, 4800);
    }
  }, [lastEditedRedirectId]);

  const getData = async () => {
    await RedirectsService.getApiRedirects()
      .then((res) => {
        if (res) {
          if (res[0]) {
            const uniqueApplications = Array.from(
              res.reduce((accumulator, redirect) => {
                if (redirect.application) {
                  accumulator.add(redirect.application);
                }
                return accumulator;
              }, new Set<string>())
            );
            setApplications(uniqueApplications);
          }
          setData(res);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const createRedirectCreateUpdateDTO = (redirectDto: RedirectDTO) => {
    return {
      newGuid: newGuid ?? "",
      targetUrl: redirectDto.targetUrl,
      aliasSuffix: redirectDto.aliasSuffix,
      enabled: redirectDto.enabled,
      validFrom: redirectDto.validFrom,
      validTo: redirectDto.validTo,
      splash: redirectDto.splash,
      disposable: redirectDto.disposable,
      application: redirectDto.application,
    } as RedirectCreateUpdateDTO;
  };

  const handleSaveRedirectDto = async (redirectDto: RedirectDTO | null) => {
    if (!redirectDto) return;

    const redirectCreateUpdateDTO = createRedirectCreateUpdateDTO(redirectDto);
    if (redirectDto.rowKey) {
      if (areRedirectDTOsEqual(selectedDto, unsavedDto)) {
        setConfirmDialogType(CONFIRM.NO_CHANGES_TO_SAVE);
        setOpenConfirmDialog(true);
        return;
      }
      await RedirectsService.putApiRedirects(
        redirectDto.rowKey,
        redirectCreateUpdateDTO
      )
        .then(() => {
          setRefresh(true);
          notify(
            TOAST.SUCCESS,
            `${redirectDto.rowKey} szerkesztve`,
            dispatchToast
          );
          setOpenFormDialog(false);
          setSelectedDto(null);
          setUnsavedDto(null);
          setLastEditedRedirectId(redirectDto.rowKey);
        })
        .catch((e) => {
          notify(
            TOAST.ERROR,
            `${redirectDto.rowKey} szerkesztése nem volt sikeres. ${e.status} - ${e.message}`,
            dispatchToast
          );
        });
    } else
      await RedirectsService.postApiRedirects(redirectCreateUpdateDTO)
        .then(() => {
          setRefresh(true);
          notify(TOAST.SUCCESS, `${newGuid} hozzáadva`, dispatchToast);
          setLastEditedRedirectId(newGuid);
          setOpenFormDialog(false);
          setSelectedDto(null);
          setUnsavedDto(null);
        })
        .catch((e: ApiError) => {
          notify(
            TOAST.ERROR,
            `A hozzáadás nem volt sikeres. ${e.status} - ${e.message}`,
            dispatchToast
          );
        });
  };

  const openDeleteDtoConfirmDialog = () => {
    setOpenConfirmDialog(true);
    setConfirmDialogType(CONFIRM.DELETE_DTO);
  };

  const handleClickCloneDto = () => {
    if (selectedDto) {
      let clonedDto = {
        rowKey: null,
        targetUrl: selectedDto?.targetUrl,
        aliasSuffix: selectedDto?.aliasSuffix,
        enabled: selectedDto?.enabled,
        validFrom: selectedDto?.validFrom,
        validTo: selectedDto?.validTo,
        splash: selectedDto?.splash,
        disposable: selectedDto?.disposable,
        createdBy: null,
        rowVersion: null,
        lastUsed: null,
        application: selectedDto?.application,
        clickCount: undefined,
        isDeleted: selectedDto?.isDeleted,
      } as RedirectDTO;
      setSelectedDto(null);
      openFormDialogWindow(clonedDto);
    }
  };

  const handleDeleteRedirect = async () => {
    if (!selectedDto?.rowKey) {
      return;
    }
    await RedirectsService.deleteApiRedirects(selectedDto.rowKey)
      .then(() => {
        setRefresh(true);
        notify(TOAST.SUCCESS, `${selectedDto.rowKey} törölve`, dispatchToast);
        setOpenConfirmDialog(false);
        setSelectedDto(null);
      })
      .catch((e) =>
        notify(
          TOAST.ERROR,
          `${selectedDto.rowKey} törlése nem volt sikeres`,
          dispatchToast
        )
      );
  };

  const onCloseFormDialog = () => {
    if (!areRedirectDTOsEqual(selectedDto, unsavedDto)) {
      setConfirmDialogType(CONFIRM.UNSAVED_CHANGES);
      setOpenConfirmDialog(true);
    } else {
      setNewGuid(null);
      setOpenFormDialog(false);
    }
  };

  const handleFormDialogClose = () => {
    setOpenConfirmDialog(false);
    setOpenFormDialog(false);
  };

  const getConfirmHandleAction = () => {
    if (confirmDialogType === CONFIRM.UNSAVED_CHANGES) {
      return handleFormDialogClose;
    } else if (confirmDialogType === CONFIRM.NO_CHANGES_TO_SAVE) {
      return null;
    } else {
      return handleDeleteRedirect;
    }
  };

  const filterData = () => {
    if (!filters.application && !filters.phrase && !filters.enabled)
      return data;
    else {
      const lowerCasePhrase = (filters.phrase || "").toLowerCase();
      return data.filter((d) => {
        const applicationMatch =
          !filters.application ||
          (d.application && filters.application?.includes(d.application)) ||
          filters.application?.length === 0;

        const enabledMatch =
          !filters.enabled ||
          filters.enabled?.length === 0 ||
          (filters.enabled.includes("Aktív") &&
            filters.enabled.includes("Nem aktív")) ||
          filters.enabled?.includes("Aktív") === d.enabled;

        const phraseMatch =
          !filters.phrase ||
          d.aliasSuffix?.toLowerCase().includes(lowerCasePhrase) ||
          d.targetUrl?.toLowerCase().includes(lowerCasePhrase) ||
          d.createdBy?.toLowerCase().includes(lowerCasePhrase) ||
          d.rowKey?.toLowerCase().includes(lowerCasePhrase);

        return applicationMatch && enabledMatch && phraseMatch;
      });
    }
  };

  const multiselectRef = React.createRef() as any;
  const resetFilters = () => {
    setFilters({
      enabled: [],
      application: [],
      phrase: "",
    } as Filters);
    localStorage.setItem(
      LOCAL_STORAGE_KEY.FILTER,
      JSON.stringify({
        enabled: [],
        application: [],
        phrase: "",
      } as Filters)
    );
    multiselectRef?.current && multiselectRef.current.resetSelectedValues();
  };

  const hasActiveFilters = () =>
    filters &&
    ((filters.application && filters.application?.length > 0) ||
      (filters.enabled && filters.enabled?.length > 0) ||
      filters.phrase);

  function handleSelect(selectedList: any, selectedItem: any): void {
    const property =
      selectedItem.cat === "Állapot"
        ? "enabled"
        : selectedItem.cat === "Alkalmazás"
        ? "application"
        : "";

    if (property !== "") {
      setFilters(
        (prev: Filters) =>
          ({
            ...prev,
            [property]: selectedList
              .filter((x: any) => x.cat === selectedItem.cat)
              ?.map((y: any) => y.key),
          } as Filters)
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEY.FILTER,
        JSON.stringify(
          (prev: Filters) =>
            ({
              ...prev,
              [property]: selectedList
                .filter((x: any) => x.cat === selectedItem.cat)
                ?.map((y: any) => y.key),
            } as Filters)
        )
      );
    }
  }

  function getOptions(): any {
    const enabledStateOptions = [
      {
        cat: "Állapot",
        key: "Aktív",
      },
      {
        cat: "Állapot",
        key: "Nem aktív",
      },
    ];
    const appOptions = applications?.map((app: string) => ({
      cat: "Alkalmazás",
      key: app,
    }));
    return appOptions?.length > 0
      ? enabledStateOptions.concat(appOptions)
      : enabledStateOptions;
  }

  return (
    <div className={styles.main}>
      <div className={styles.buttons}>
        <div style={{ display: "inline-flex" }}>
          <Input
            onChange={(e: any, data: any) => {
              setFilters((prev: Filters) => ({ ...prev, phrase: data.value }));
              localStorage.setItem(
                LOCAL_STORAGE_KEY.FILTER,
                JSON.stringify((prev: Filters) => ({
                  ...prev,
                  phrase: data.value,
                }))
              );
            }}
            contentBefore={<SearchIcon />}
            id={useId("content-before")}
            size="small"
            placeholder="Keresés..."
            style={{
              margin: "15px 5px",
              marginTop: "15px",
              width: "250px",
              height: "36px",
            }}
            value={filters.phrase ?? ""}
          />
          <div style={{ margin: "15px 5px" }}>
            <Multiselect
              displayValue="key"
              groupBy="cat"
              placeholder={hasActiveFilters() ? "" : "Szűrőfeltételek"}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={handleSelect}
              onSearch={function noRefCheck() {}}
              onSelect={handleSelect}
              options={getOptions()}
              ref={multiselectRef as any}
              showCheckbox
            />
          </div>
          <ResetIcon
            style={{
              marginTop: "22px",
              cursor: hasActiveFilters() ? "pointer" : "auto",
              color: hasActiveFilters() ? "#5F6371" : "gray",
            }}
            onClick={resetFilters}
          />
        </div>
        <div>
          <Button
            className={styles.button}
            appearance="primary"
            icon={<AddIcon />}
            onClick={() =>
              openFormDialogWindow(
                JSON.parse(JSON.stringify(defaultRedirectDto))
              )
            }
          >
            Új hozzáadása
          </Button>

          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button
                className={styles.button}
                appearance={selectedDto?.rowKey ? "primary" : "subtle"}
              >
                Műveletek
              </Button>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  disabled={!selectedDto?.rowKey}
                  onClick={openEditFormDialogWindow}
                >
                  {"Szerkesztés"}
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon />}
                  disabled={!selectedDto?.rowKey}
                  onClick={openDeleteDtoConfirmDialog}
                >
                  {"Törlés"}
                </MenuItem>
                <MenuItem
                  disabled={!selectedDto?.rowKey}
                  icon={<CopyIcon />}
                  onClick={handleClickCloneDto}
                >
                  {"Új a kiválasztott alapján"}
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </div>
      <TableComponent
        lastEditedRedirectId={lastEditedRedirectId}
        data={filterData()}
        openEditFormDialogWindow={openEditFormDialogWindow}
        selectedDto={selectedDto}
        setSelectedDto={setSelectedDto}
        isLoading={isLoading}
      />

      <DialogFormComponent
        applications={applications}
        open={openFormDialog}
        setOpen={setOpenFormDialog}
        handleSave={handleSaveRedirectDto}
        selectedDto={selectedDto}
        unsavedDto={unsavedDto}
        setUnsavedDto={setUnsavedDto}
        handleClose={onCloseFormDialog}
        newGuid={newGuid}
      />
      <DialogConfirmComponent
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        type={confirmDialogType}
        handleAction={getConfirmHandleAction()}
      />
    </div>
  );
}
