import { LOCAL_STORAGE_KEY } from "../Enums";
import {
  graphAPIRequest,
  msalInstance,
  redirectorLoginRequest,
} from "./AuthProvider";

export const handleLogin = async () => {
  await msalInstance
    .handleRedirectPromise()
    .then(() => {
      msalInstance.loginRedirect(redirectorLoginRequest).catch(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
    })
    .catch((error) => {
      if (error.errorCode === "server_error") {
        error.errorMessage.includes("AADB2C99002")
          ? console.log(error.errorMessage)
          : console.log(error.errorMessage);
      } else console.log(error.errorMessage);
    });
};

export const handleGraphApiLogin = async () => {
  await msalInstance.loginRedirect(graphAPIRequest);
  await msalInstance
    .handleRedirectPromise()
    .then(() => {
      msalInstance.loginRedirect(graphAPIRequest).catch((e) => {
        console.log(e);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleLogout = () => {
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: "/",
  };

  msalInstance
    .logoutRedirect(logoutRequest)
    .then(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.MS_AUTH_TOKEN);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const acquireToken = async () => {
  const request = {
    ...redirectorLoginRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch(() => {
        handleLogout();
      });
    });
};

export const acquireGraphApiToken = async () => {
  const request = {
    ...graphAPIRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch((error) => {
        return null;
      });
    });
};
