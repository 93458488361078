"use client";
// Import necessary dependencies from 'react'
import { useEffect, useState } from "react";
// Import necessary dependencies from '@fluentui/react-components'

import { OpenAPI } from "./RedirectorService/core/OpenAPI";
import {
  ToasterContext,
  RefreshContext,
  RefreshProps,
  FilterContext,
  FilterProps,
  ThemeContext,
  ThemeProps,
} from "./utils/GlobalContexts";
import { acquireToken } from "./utils/auth/AuthActions";
import {
  createDOMRenderer,
  RendererProvider,
  FluentProvider,
  SSRProvider,
  useId,
  Toaster,
} from "@fluentui/react-components";
import { Filters } from "./models/Filters";
import {
  darkTheme,
  blueTheme,
  lightTheme,
} from "./utils/FluentThemeConstructor";

const renderer = createDOMRenderer();

export function Providers({ children }: any) {
  OpenAPI.BASE = process.env.REACT_APP_REDIRECTOR_API_URI as string;
  OpenAPI.TOKEN = async () => (await acquireToken()) as any;

  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    enabled: [],
    application: [],
    phrase: "",
  } as Filters);
  const toasterId = useId("toaster");
  const [theme, setTheme] = useState(lightTheme);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  darkTheme.colorBrandForeground1 = blueTheme[110];
  darkTheme.colorBrandForeground2 = blueTheme[120];

  return (
    <RendererProvider renderer={renderer || createDOMRenderer()}>
      <SSRProvider>
        <FluentProvider theme={theme}>
          <div className="App">
            <ToasterContext.Provider value={{ toasterId: toasterId } || ""}>
              <Toaster
                toasterId={toasterId}
                pauseOnHover
                pauseOnWindowBlur
                timeout={4000}
              />
              <RefreshContext.Provider
                value={{ refresh, setRefresh } as RefreshProps}
              >
                <FilterContext.Provider
                  value={{ filters, setFilters } as FilterProps}
                >
                  <ThemeContext.Provider
                    value={{ theme, setTheme } as ThemeProps}
                  >
                    {children}{" "}
                  </ThemeContext.Provider>
                </FilterContext.Provider>
              </RefreshContext.Provider>
            </ToasterContext.Provider>
          </div>
        </FluentProvider>
      </SSRProvider>
    </RendererProvider>
  );
}
