import { Button, Spinner } from "@fluentui/react-components";
import { useStylesMainPage } from "../fluentui-styles/MainPageStyle";
import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import Footer from "../footer/Footer";
import { Navigate } from "react-router-dom";
import { handleLogin } from "../../utils/auth/AuthActions";
import { INTERNAL_ROUTE } from "../../utils/Enums";
import { ClipLoader } from "react-spinners";
import { redirectorLoginRequest } from "../../utils/auth/AuthProvider";

const LoginPage = () => {
  const styles = useStylesMainPage();
  const { accounts, inProgress } = useMsal();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(false);
    let action = () => console.log();
    let timer = setTimeout(() => {
      action();
    }, 2000);
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0)
        action = () => <Navigate to={INTERNAL_ROUTE.REDIRECTS} />;
      else action = () => setShowButton(true);
    }

    return () => clearTimeout(timer);
  }, [accounts]);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setShowButton(false);
    if (!isAuthenticated) {
      instance
        .ssoSilent(redirectorLoginRequest)
        .then((res) => instance.setActiveAccount(res.account))
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect(redirectorLoginRequest);
          }
        });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <h1 className={styles.loginAppTitle}>Redirector</h1>
        {showButton ? (
          <Button
            className={styles.loginButton}
            iconPosition="after"
            appearance="primary"
            onClick={handleLogin}
          >
            BEJELENTKEZÉS
          </Button>
        ) : (
          <ClipLoader size={80} color="#5F6371" />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
