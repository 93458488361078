import { Button, Input, Label, useId } from "@fluentui/react-components";
import { Fragment, useEffect } from "react";
import { InputProps } from "../interfaces/InputWithLabelProps";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ResetIcon } from "../../utils/Icons";
import { useStylesInput } from "../fluentui-styles/InputStyle";

const InputComponent: React.FC<InputProps> = ({
  label,
  value,
  name,
  onChange,
  datetime,
  disabled,
}) => {
  const styles = useStylesInput();
  const smallId = useId("input-small");
  function addZeroBefore(n: number | undefined | null) {
    return !n ? "00" : (n < 10 ? "0" : "") + n;
  }

  const changeTimeValue = (e: any, time: any) => {
    const [hours, minutes] = time?.value?.split(":");
    const newTime = value ?? new Date();
    newTime.setHours(parseInt(hours));
    newTime.setMinutes(parseInt(minutes));
    onChange(newTime);
  };

  const getTimeValue = () =>
    value
      ? `${addZeroBefore(value?.getHours())}:${addZeroBefore(
          value?.getMinutes()
        )}`
      : "";

  return (
    <Fragment>
      <Label size="small" htmlFor={smallId}>
        {label}
      </Label>
      {datetime ? (
        <div>
          <DatePicker
            size="small"
            className={styles.datepicker}
            onSelectDate={onChange}
            value={value}
            allowTextInput
            formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
          />
          <Input
            size="small"
            type="time"
            value={getTimeValue()}
            onChange={changeTimeValue}
          />
          <ResetIcon className={styles.reset} onClick={() => onChange(null)} />
        </div>
      ) : (
        <Input
          disabled={disabled}
          size="small"
          id={smallId}
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    </Fragment>
  );
};

export default InputComponent;
