import { makeStyles, shorthands } from "@fluentui/react-components";

export const profileInfoBoxStyles = makeStyles({
  container: {
    position: "absolute",
    display: "inline-flex",
    right: "20px",
    fontFamily: "'Roboto', sans-serif",
    cursor: "pointer",
    ":hover": { backgroundColor: "transparent" },
  },
  containerInner: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "8px",
    lineHeight: "1",
    textAlign: "right",
  },
  avatar: {
    ":hover": {
      transform: "scale(1.05)",
      ...shorthands.transition("all", "0.5s"),
    },
  },
  displayName: { fontSize: "14px", fontWeight: 700 },
  jobTitle: { fontSize: "12px", fontWeight: 400 },
  logoutButton: {
    marginTop: "5px",
    ...shorthands.padding("5px"),
    ":hover": {
      backgroundColor: "#f71622",
      color: "#ffffff",
    },
  },
});
