import {
  DialogActions,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { DialogActionsProps } from "../interfaces/DialogActionsProps";

const DialogActionsComponent: React.FC<DialogActionsProps> = ({
  closeBtnText,
  saveBtnText,
  saveAction,
}) => {
  return (
    <DialogActions>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="secondary">{closeBtnText ?? "Mégse"}</Button>
      </DialogTrigger>
      {saveAction && (
        <Button appearance="primary" onClick={saveAction}>
          {saveBtnText}
        </Button>
      )}
    </DialogActions>
  );
};

export default DialogActionsComponent;
