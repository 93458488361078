import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import { DialogFormProps } from "../interfaces/DialogFormProps";
import { useStylesFormDialog } from "../fluentui-styles/FormDialogStyle";
import { v4 as uuidv4 } from "uuid";

import {
  Label,
  InputProps,
  Switch,
  SwitchOnChangeData,
  useToastController,
  Combobox,
  useId,
  Option,
  ComboboxProps,
  Tooltip,
} from "@fluentui/react-components";
import { Fragment, useEffect } from "react";
import { CopyIcon, LinkIcon } from "../../utils/Icons";
import { RedirectDTO } from "../../RedirectorService";
import { notify } from "../../utils/DispatchToaster";
import { TOAST } from "../../utils/Enums";
import { ToasterContext } from "../../utils/GlobalContexts";

const DialogFormComponent: React.FC<DialogFormProps> = ({
  open,
  setOpen,
  handleSave,
  selectedDto,
  unsavedDto,
  setUnsavedDto,
  handleClose,
  applications,
  newGuid,
}) => {
  const styles = useStylesFormDialog();
  const originalRedirectUri = `${process.env.REACT_APP_REDIRECTOR_API_URI}/${
    selectedDto?.aliasSuffix ?? ""
  }`;

  const handleChangeDto: InputProps["onChange"] = async (
    event: any,
    data: any
  ) =>
    setUnsavedDto((prev: any) => ({
      ...prev,
      [event.target.name]: data.value,
    }));

  const changeDateTimeProp = (
    date: Date | null | undefined,
    property: string
  ) =>
    setUnsavedDto((prev: any) => ({
      ...prev,
      [property]: date,
    }));

  const handleChangeSwitch = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) =>
    setUnsavedDto((prev: any) => ({
      ...prev,
      [ev.target.name]: data.checked,
    }));

  const openUri = () => window?.open(originalRedirectUri, "_blank")?.focus();

  const { toasterId } = React.useContext(ToasterContext);
  const { dispatchToast } = useToastController(toasterId);
  const [matchingOptions, setMatchingOptions] = React.useState([
    ...(applications ?? []),
  ]);

  const onChange: ComboboxProps["onChange"] = (event) => {
    const value = event.target.value.trim();
    setUnsavedDto((prev) => ({
      ...prev,
      application: value,
    }));
    if (!value || value === "") setMatchingOptions(applications);
    else {
      const matches = applications?.filter(
        (option) => option.toLowerCase().indexOf(value.toLowerCase()) === 0
      );
      setMatchingOptions(matches);
    }
  };

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={
        selectedDto?.rowKey ? "Redirect szerkesztése" : "Új redirect hozzáadása"
      }
      saveAction={() => handleSave(unsavedDto)}
      saveBtnText={"Mentés"}
      handleDialogOpenStateChange={handleClose}
    >
      <div className={styles.content}>
        {!unsavedDto?.rowKey && (
          <div className={styles.labelRow}>
            <div className={styles.labelRowItem}>
              <Label size="small">{"Redirector ID"}</Label>
            </div>
          </div>
        )}
        {!unsavedDto?.rowKey && (
          <div className={styles.labelRow}>
            <div
              className={styles.labelRowItem}
              style={{ marginBottom: "5px" }}
            >
              <Label size="small">{newGuid}</Label>
            </div>
          </div>
        )}
        {unsavedDto?.rowKey && (
          <Fragment>
            <div className={styles.labelRow} style={{ flexDirection: "row" }}>
              <div className={styles.labelRowItem}>
                <Label size="small">{"Redirector ID"}</Label>
              </div>
              <div className={styles.labelRowItem}>
                <Label size="small">{"Redirector Url"}</Label>
              </div>
            </div>
            <div className={styles.item} style={{ flexDirection: "row" }}>
              <div
                className={styles.labelRowItem}
                style={{ wordBreak: "break-all" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "24px",
                  }}
                >
                  {unsavedDto?.rowKey}
                </div>
              </div>
              <div
                className={styles.labelRowItem}
                style={{ wordBreak: "break-all" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {originalRedirectUri}
                  <CopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(originalRedirectUri);
                      notify(
                        TOAST.SUCCESS,
                        "Hivatkozás másolva",
                        dispatchToast
                      );
                    }}
                    style={{ marginLeft: "10px" }}
                    className="scaledIcon"
                  />

                  <LinkIcon
                    onClick={openUri}
                    style={{ marginLeft: "10px" }}
                    className="scaledIcon"
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <div className={styles.item}>
          <InputComponent
            label="Cél url"
            name="targetUrl"
            value={unsavedDto?.targetUrl ?? ""}
            onChange={handleChangeDto}
          />
        </div>

        <div className={styles.item}>
          <InputComponent
            label="Alias"
            name="aliasSuffix"
            value={unsavedDto?.aliasSuffix ?? ""}
            onChange={handleChangeDto}
          />
        </div>
        <div className={styles.item}>
          <Label size="small" htmlFor={useId("label-smallId")}>
            {"Alkalmazás neve"}
          </Label>
          <Combobox
            size="small"
            aria-labelledby={useId("combo-default")}
            onOptionSelect={(e, d) =>
              setUnsavedDto((prev) => ({
                ...prev,
                application: d.optionValue,
              }))
            }
            onChange={onChange}
            value={unsavedDto?.application ?? ""}
          >
            {matchingOptions?.length > 0
              ? matchingOptions?.map((option) => (
                  <Option style={{ height: "10px" }} key={option}>
                    {option}
                  </Option>
                ))
              : applications?.map((option) => (
                  <Option style={{ height: "10px" }} key={option}>
                    {option}
                  </Option>
                ))}
          </Combobox>
        </div>
        <div className={styles.labelRow} style={{ flexDirection: "row" }}>
          <div className={styles.labelRowItem}>
            <Label size="small">{"Érvényesség kezdet"}</Label>
          </div>
          <div className={styles.labelRowItem}>
            <Label size="small">{"Érvényesség vége"}</Label>
          </div>
        </div>
        <div className={styles.item} style={{ flexDirection: "row" }}>
          <div className={styles.labelRowItem}>
            <InputComponent
              datetime
              name="validFrom"
              value={
                unsavedDto?.validFrom ? new Date(unsavedDto?.validFrom) : null
              }
              onChange={(data: any) => changeDateTimeProp(data, "validFrom")}
            />
          </div>
          <div className={styles.labelRowItem}>
            <InputComponent
              datetime
              name="validTo"
              value={unsavedDto?.validTo ? new Date(unsavedDto?.validTo) : null}
              onChange={(data: any) => changeDateTimeProp(data, "validTo")}
            />
          </div>
        </div>
        <span>
          <Switch
            name="enabled"
            checked={unsavedDto?.enabled ?? true}
            onChange={handleChangeSwitch}
            label={"Aktív"}
          />
          <Switch
            name="splash"
            checked={unsavedDto?.splash ?? false}
            onChange={handleChangeSwitch}
            label={"Átirányító oldal"}
          />
          <Switch
            name="disposable"
            checked={unsavedDto?.disposable ?? false}
            onChange={handleChangeSwitch}
            label={"Egyszer használható"}
          />
        </span>
      </div>
    </DialogSkeleton>
  );
};

export default DialogFormComponent;
