/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedirectCreateUpdateDTO } from '../models/RedirectCreateUpdateDTO';
import type { RedirectDTO } from '../models/RedirectDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RedirectsService {

    /**
     * @returns RedirectDTO Success
     * @throws ApiError
     */
    public static getApiRedirects(): CancelablePromise<Array<RedirectDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/redirects',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiRedirects(
requestBody?: RedirectCreateUpdateDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/redirects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns RedirectDTO Success
     * @throws ApiError
     */
    public static getApiRedirects1(
id: string,
): CancelablePromise<RedirectDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/redirects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns RedirectDTO Success
     * @throws ApiError
     */
    public static putApiRedirects(
id: string,
requestBody?: RedirectCreateUpdateDTO,
): CancelablePromise<RedirectDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/redirects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiRedirects(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/redirects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param guidOrAlias 
     * @returns any Success
     * @throws ApiError
     */
    public static get(
guidOrAlias: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{guidOrAlias}',
            path: {
                'guidOrAlias': guidOrAlias,
            },
        });
    }

}
