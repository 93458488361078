import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import LoginPage from "./components/login/LoginPage";
import {
  msalInstance,
  redirectorLoginRequest,
} from "./utils/auth/AuthProvider";
import { Providers } from "./providers";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useEffect } from "react";
import { InteractionRequiredAuthError } from "msal";

export default function Auth({ children }: { children: React.ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Providers>
        <AuthenticatedTemplate>
          <Header />
          {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
      </Providers>
    </MsalProvider>
  );
}
