import React from "react";
import { Filters } from "../models/Filters";
import { lightTheme } from "./FluentThemeConstructor";
import { Theme } from "@fluentui/react-components";

export type ThemeProps = {
  theme: Theme;
  setTheme: any;
};

export const ThemeContext = React.createContext<ThemeProps>({
  theme: lightTheme,
  setTheme: null,
});

export type RefreshProps = {
  refresh: boolean;
  setRefresh: any;
};

export const RefreshContext = React.createContext<RefreshProps>({
  refresh: false,
  setRefresh: null,
});

export type FilterProps = {
  filters: Filters;
  setFilters: any;
};

export const FilterContext = React.createContext<FilterProps>({
  filters: {
    enabled: [],
    application: [],
    phrase: "",
  } as Filters,
  setFilters: null,
});

export const ToasterContext = React.createContext<ToasterProps>({
  toasterId: "",
});

export type ToasterProps = {
  toasterId: string;
};
