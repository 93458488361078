import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesFooter = makeStyles({
  footer: {
    textAlign: "center",
    bottom: 0,
    marginBottom: "10px",
    width: "100%",
    ...shorthands.padding("24px 50px"),
  },
  mainPagePosition: { position: "fixed" },
  otherPosition: { position: "absolute" },
  text: {
    fontWeight: 400,
    display: "block",
    color: "rgba(0, 0, 0, 0.88)",
    fontSize: "14px",
  },
});
